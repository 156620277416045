import { useState, useEffect } from "react";
import axios from "axios";

const VM_API_URL = process.env.REACT_APP_API_2_URL;

const getInstance = () => {
    const abortController = new AbortController();
    const instance = axios.create({
        baseURL: VM_API_URL,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        signal: abortController.signal,
    });
    return { instance, abortController };
};

export const useMinistries = (shouldFetch = true) => {
    const endpoint = "/vm/ministries";
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!shouldFetch) return;
        const { instance, abortController } = getInstance();
        const fetchData = async () => {
            setIsLoading(true);
            setIsError(false);
            setError(null);
            try {
                const response = await instance.get(endpoint);
                setData(response.data);
            } catch (err) {
                setIsError(true);
                setError(
                    err.response?.data?.message ||
                        "Terjadi kesalahan saat mengambil data"
                );
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
        return () => {
            abortController.abort();
        };
    }, [shouldFetch]);

    return { data, isLoading, isError, error };
};
